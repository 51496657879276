import { menuItems } from "@peracto/peracto";
import { faTools } from '@fortawesome/pro-regular-svg-icons/faTools'

const ROLE_ADMIN = 'ROLE_ADMIN'

export const menu = {
    ...menuItems,
    testHarnesses: {
        label: "Test Harnesses",
        icon: faTools,
        sortOrder: 72,
        roles: [ROLE_ADMIN],
        children: {
            hollanderParts: {
                label: "Hollander Parts",
                sortOrder: 10,
                roles: [ROLE_ADMIN],
                path: "/test-harnesses/hollander-parts",
            },
            hollanderSendOrder: {
                label: "Hollander Send Order",
                sortOrder: 20,
                roles: [ROLE_ADMIN],
                path: "/test-harnesses/hollander-send-order",
            },
        },
    },
};
