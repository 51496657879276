const sharedClasses =
    "flex items-center justify-center text-[16px] leading-[1] w-auto px-[30px] pt-[13px] pb-[12px] transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-25";

export const primary = {
    solidClasses: `${sharedClasses} font-heading font-bold uppercase bg-brand-primary text-brand-white hover:bg-brand-primary-darken hover:text-brand-white active:bg-brand-primary-darken active:shadow-[0_0_0_4px_rgba(20,89,54,0.4)]`,
    outlineClasses: `${sharedClasses} font-heading font-bold uppercase bg-none text-brand-primary border-2 border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary-darken active:shadow-[0_0_0_4px_rgba(20,89,54,0.4)]`,
    linkClasses: `${sharedClasses} underline hover:underline flex items-center justify-center w-auto text-brand-primary hover:text-brand-primary-darken`,
};

export const secondary = {
    solidClasses: `${sharedClasses} font-heading font-bold uppercase bg-brand-secondary text-brand-white hover:bg-brand-secondary-darken hover:text-brand-white active:bg-brand-secondary-darken active:shadow-[0_0_0_4px_rgba(33,25,90,0.4)]`,
    outlineClasses: `${sharedClasses} font-heading font-bold uppercase bg-none text-brand-secondary border-2 border-brand-secondary hover:bg-brand-secondary hover:text-brand-white active:bg-brand-secondary-darken active:shadow-[0_0_0_4px_rgba(33,25,90,0.4)]`,
    linkClasses: `${sharedClasses} underline hover:underline flex items-center justify-center w-auto text-brand-secondary hover:text-brand-secondary-darken`,
};
