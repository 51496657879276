import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons/faTimesCircle";

export const StockMessage = (props) => {
  const { iconColor, inStock, textColor, className } = props;

  return (
    <div
      className={`flex flex-row items-center text-${iconColor}`}
      data-testid="stock_message"
    >
      <FontAwesomeIcon icon={inStock ? faCheckCircle : faTimesCircle} />
      <p className={`text-${textColor}  ml-[10px] mb-0 ${className}`}>
        {inStock ? "In stock" : "Out of stock"}
      </p>
    </div>
  );
};

StockMessage.defaultProps = {
  inStock: true,
  textColor: "brand-primary",
  iconColor: "brand-primary",
  className: "",
};
