/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { useConfig } from "@peracto/peracto-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import isEmpty from "lodash/isEmpty";
import { ProductCard } from "../../../components/ProductCard";

// Attributes that don't start with 'attributes.*'
const UNPREFIXED_ATTRIBUTES = ["sku"];

let Icon = () => (
  <div className="flex items-center justify-center w-full py-10">
    <FontAwesomeIcon icon={faBoxFull} size="6x" />
  </div>
);

const Hits = (props) => {
  const { hits, isEditMode } = props;

  const inStorefront = !window?.location?.pathname.includes("/content/edit/");

  return hits.length > 0 ? (
    <>
      {hits.map((hit, index) => {
        return (
          <ProductCard
            key={`product-${index}`}
            data-testid={"products__item"}
            product={hit}
            link={(e) => {
              e.preventDefault();
            }}
            hideAddToBasket={true}
            imageProps={{
              height: {
                base: "auto",
                md: "auto",
                lg: "auto",
                xl: "auto",
              },
              width: {
                base: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
            }}
            imagePresets={{
              base: "small",
              md: "medium",
              lg: "large",
            }}
          />
        );
      })}
    </>
  ) : isEditMode || !inStorefront ? (
    <Icon />
  ) : null;
};
const CustomHits = connectHits(Hits);

const ProductsContent = ({
  numberOfProducts = 4,
  attributes = [],
  attributeOperator = "and",
  hitsAlignment = "flex-start",
  hitsDirection = "row",
  productCardDirection = "column",
  fontColour = "#000",
  fontFamily = "body",
  fallbackImage = "/images/placeholder.jpg",
  isEditMode,
}) => {
  const config = useConfig();
  const algolia = config.get("algolia") || {};

  const { applicationId, searchApiKey, indexName } = algolia;

  const searchClient = algoliasearch(applicationId, searchApiKey);

  const [facetFilters, setFacetFilters] = useState();

  useEffect(() => {
    if (attributes?.length > 0) {
      const categories = attributes?.reduce((acc, { attribute, value }) => {
        const attributeKey = UNPREFIXED_ATTRIBUTES.includes(attribute)
          ? attribute
          : `attributes.${attribute}`;
        if (value.length > 0) acc.push(`${attributeKey}:${value}`);

        return acc;
      }, []);

      setFacetFilters(categories);
    }
  }, [attributes]);

  if (isEmpty(algolia)) {
    console.error(
      "No Algolia configuration defined. Please refer to the Peracto documentation."
    );

    return null;
  }

  return useMemo(
    () => (
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure
          hitsPerPage={numberOfProducts}
          facetFilters={
            facetFilters
              ? attributeOperator === "and"
                ? facetFilters
                : [facetFilters]
              : null
          }
        />

        <CustomHits
          isEditMode={isEditMode}
          productCardDirection={productCardDirection}
          hitsDirection={hitsDirection}
          fontColour={fontColour}
          fontFamily={fontFamily}
          fallbackImage={fallbackImage}
        />
      </InstantSearch>
    ),
    [
      facetFilters,
      numberOfProducts,
      attributeOperator,
      productCardDirection,
      fontColour,
      fontFamily,
      isEditMode,
      hitsAlignment,
      hitsDirection,
      fallbackImage,
    ]
  );
};

const ProductsRenderer = ({ state, ...props }) => {
  return (
    <div className="grid grid-cols-1 gap-6 product-card-container content__products md:grid-cols-2">
      <ProductsContent {...state} {...props} />
    </div>
  );
};

export default ProductsRenderer;
