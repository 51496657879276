import React from "react";
import { Route, Switch } from "react-router-dom";

import { HollanderPartsForm } from "./hollander-parts";
import { HollanderSendOrdersForm } from "./hollander-send-order";

export default () => {
    return {
        routes: [
            {
                path: "/test-harnesses",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}/hollander-parts`}
                            exact
                            component={HollanderPartsForm}
                        />
                        <Route
                            path={`${match.url}/hollander-send-order`}
                            exact
                            component={HollanderSendOrdersForm}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
