import React from "react";

import { Price } from "components/Price";

import isEmpty from "lodash/isEmpty";

export const LoggedInContent = (props) => {
  const { prices, incTax } = props;

  const lowestPrice = isEmpty(prices)
    ? {}
    : Object.entries(prices).reduce((prev: any, curr: any) => {
        if (curr === undefined) return prev;
        if (prev === undefined) return curr;
        return prev[1].inc < curr[1].inc ? prev : curr;
      });

  return (
    <div className="flex flex-col" data-testid="logged_in_content">
      {!isEmpty(lowestPrice) && (
        <div className="flex flex-row items-center md:block lg:flex gap-x-2">
          <div className="flex items-center">
            <Price
              data-testid="logged_in_lowestPrice"
              includeTax={incTax}
              price={{
                value: { inc: lowestPrice[1].inc, ex: lowestPrice[1].exc },
                label: "",
              }}
              className="text-[18px] md:text-[24px] leading-[20px] md:leading-[30px] font-bold mr-[10px]"
              labelClassName="text-[18px] md:text-[24px] leading-[20px] md:leading-[30px]"
            />

            <div
              className="flex flex-col text-brand-black text-[10px] text-bold leading-[10px]"
              data-testid="logged_in_inc_tax"
            >
              <span>INC.</span>
              <span>VAT</span>
            </div>
          </div>

          <div className="flex items-center md:mt-1 lg:mt-0">
            <Price
              data-testid="logged_in_lowestPrice"
              includeTax={false}
              price={{
                value: { inc: lowestPrice[1].inc, ex: lowestPrice[1].exc },
                label: "",
              }}
              className="text-[12px] md:text-[14px] text-brand-dark-grey leading-[20px] md:leading-[30px] font-bold lg:ml-[15px] mr-[10px]"
              labelClassName="text-[12px] md:text-[14px] leading-[20px] md:leading-[30px]"
            />

            <div
              className="flex flex-col text-brand-dark-grey text-[10px] text-bold leading-[10px]"
              data-testid="logged_in_exc_tax"
            >
              <span>EXC.</span>
              <span>VAT</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
