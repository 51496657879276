const { lighten } = require("polished");

// Used so hover colors get automatically updated
const brandPrimaryColor = "#1C9128";
const brandSecondaryColor = "#233F92";
const brandTertiaryColor = "#294DBA";
const brandBlackColor = "#3F3D4E";

const colors = {
  // Base colors
  "brand-primary": brandPrimaryColor,
  "brand-secondary": brandSecondaryColor,
  "brand-tertiary": brandTertiaryColor,
  "brand-success": "#2DB200",
  "brand-danger": "#E30613",
  "brand-warning": "#F49C10",
  "brand-info": "#0697E3",
  "brand-pale-danger": "#FED7D7",
  "brand-white": "#FFFFFF",
  "brand-light-grey": "#F9F9FA",
  "brand-grey": "#DDDDDD",
  "brand-black": brandBlackColor,

  // Hover colors
  "brand-primary-lighten": lighten(0.05, brandPrimaryColor),
  "brand-primary-darken": "#145936",

  "brand-secondary-lighten": lighten(0.05, brandSecondaryColor),
  "brand-secondary-darken": "#21195A",

  "brand-black-lighten": lighten(0.05, brandBlackColor),
  "brand-black-darken": "#213142",
};

const fonts = {
  heading: ['"Roboto Condensed", sans-serif'],
  body: ["Roboto, sans-serif"],
};

module.exports = { colors, fonts };
