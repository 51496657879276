import React from 'react'
import type { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export type PriceProps = HTMLAttributes<HTMLDivElement> & {
    currency?: string
    currencyAlignment?: 'left' | 'right'
    price: {
        value: {
            inc: number
            ex: number
        }
        label?: string
    }
    showLabel?: boolean
    labelAlignment?: 'left' | 'right'
    strikethroughLabel?: boolean
    strikethroughValue?: boolean
    strikethroughBoth?: boolean
    includeTax?: boolean
    showDecimals?: boolean
    parseAsPence?: boolean
    commaSeparation?: boolean
    labelClassName?: string
}

/** The Price component is used to format a price and label pair. Passing it configuration such as currency, strikethrough, include tax etc. allows you to build complex pricing components. */
export const Price: React.FC<PriceProps> = (props) => {
    const {
        currency,
        currencyAlignment,
        price,
        showLabel,
        labelAlignment,
        strikethroughLabel,
        strikethroughValue,
        strikethroughBoth,
        includeTax,
        showDecimals,
        parseAsPence,
        commaSeparation = false,
        className,
        labelClassName = '',
        ...restProps
    } = props
    const incTaxPrice = () => {
        let p: string | number = price.value.inc
        if (parseAsPence) p = p / 100
        if (showDecimals) p = p.toFixed(2)
        else p = Math.round(p)
        return commaSeparation ? p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : p
    }
    const excTaxPrice = () => {
        let p: string | number = price.value.ex
        if (parseAsPence) p = p / 100
        if (showDecimals) p = p.toFixed(2)
        else p = Math.round(p)
        return commaSeparation ? p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : p
    }

    return (
        <div
            className={twMerge(
                `inline-flex align-center whitespace-pre ${
                    labelAlignment === `left` ? 'flex-row' : 'flex-row-reverse'
                } ${strikethroughBoth ? `line-through` : ``} ${className}`,
            )}
            data-testid="price"
            {...restProps}
        >
            {showLabel && price.label && (
                <span
                    className={twMerge(
                        `${strikethroughLabel ? `line-through` : ``} ${labelClassName}`,
                    )}
                    data-testid="price__label"
                >
                    {price.label}
                </span>
            )}
            <span
                className={`flex ${strikethroughValue ? `line-through` : ``} ${
                    currencyAlignment === `left` ? `flex-row` : `flex-row-reverse`
                }`}
            >
                <span data-testid="price__currency">{currency}</span>
                <span
                    data-testid="price__price"
                    data-value={includeTax ? incTaxPrice() : excTaxPrice()}
                >
                    {includeTax ? incTaxPrice() : excTaxPrice()}
                </span>
            </span>
        </div>
    )
}

Price.defaultProps = {
    currency: `£`,
    currencyAlignment: `left`,
    showLabel: true,
    labelAlignment: `left`,
    strikethroughLabel: false,
    strikethroughValue: false,
    strikethroughBoth: false,
    includeTax: false,
    showDecimals: true,
    parseAsPence: true,
}
