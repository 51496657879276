import React, { useEffect, useRef } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    usePDF,
} from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import dayjs from "dayjs";
import { useConfig } from "@peracto/peracto-config";
import { formatAsCurrency } from "../OrdersView";

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 20;
const COLN_WIDTH = (100 - COL1_WIDTH) / 4;

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#fff",
        padding: 10,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    image: {
        marginTop: 0,
        marginBottom: 15,
        width: 150,
    },
    header: {
        fontSize: 20,
        marginBottom: 15,
    },
    infoBar: {
        fontSize: 12,
        marginBottom: 15,
    },
    bold: {
        fontWeight: "bold",
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "none",
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol1Header: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol1: {
        width: COL1_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
    },
    tableCell: {
        margin: 5,
        fontSize: 10,
    },
});

export const PickListActionPanel = ({ selectedRows, instance }) => {
    const config = useConfig();

    const { orders } = config.get("features", {});

    const logo =
        orders?.pickListsPDF?.logo || "https://i.imgur.com/fSmqcDf.png";
    const header = orders?.pickListsPDF?.headerText || "Peracto Picking List";

    const includeTax = orders?.includeTax ?? true;

    const selectedRowData = instance.data.filter((row) =>
        selectedRows.includes(`${row.originId}`)
    );

    const btnRef = useRef();

    const PickListDocument = (
        <Document>
            {selectedRowData.map((row, idx) => (
                <Page
                    size="A4"
                    orientation="landscape"
                    style={styles.page}
                    key={`pick-list_page-${idx + 1}`}
                >
                    <View style={styles.section}>
                        <Image src={logo} style={styles.image} />

                        <Text style={styles.header}>{header}</Text>
                        <Text style={styles.infoBar}>
                            <Text
                                style={{
                                    fontWeight: "bold",
                                }}
                            >
                                Order:
                            </Text>{" "}
                            {row.originId} (
                            {row.billingAddress?.title
                                ? `${row.billingAddress.title} `
                                : ""}
                            {row.billingAddress?.forename}{" "}
                            {row.billingAddress?.surname}){"  "}
                            <Text
                                style={{
                                    fontWeight: "bold",
                                    marginLeft: 10,
                                }}
                            >
                                Date:
                            </Text>{" "}
                            <Text
                                style={{
                                    fontWeight: "normal",
                                }}
                            >
                                {dayjs(row.createdAt).format("DD/MM/YYYY")}
                            </Text>
                            {"  "}
                            <Text
                                style={{
                                    fontWeight: "bold",
                                    marginLeft: 10,
                                }}
                            >
                                Status:
                            </Text>{" "}
                            In Picking{"  "}
                            {row.shippingServiceName && (
                                <>
                                    <Text
                                        style={{
                                            fontWeight: "bold",
                                            marginLeft: 10,
                                        }}
                                    >
                                        Delivery:{" "}
                                    </Text>{" "}
                                    {row.shippingServiceName}
                                </>
                            )}
                        </Text>

                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol1Header}>
                                    <Text style={styles.tableCellHeader}>
                                        Image
                                    </Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>
                                        Quantity
                                    </Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>
                                        SKU
                                    </Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>
                                        Product Name
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        ...styles.tableColHeader,
                                        borderRightWidth: 0,
                                    }}
                                >
                                    <Text style={styles.tableCellHeader}>
                                        Price
                                    </Text>
                                </View>
                            </View>

                            {row.lines.length > 0 &&
                                row.lines.map((line, lineIdx) => (
                                    <View
                                        style={styles.tableRow}
                                        key={`pick-list_page-${
                                            idx + 1
                                        }-item-${lineIdx}`}
                                    >
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCell}>
                                                <Image
                                                    src={
                                                        line?.item?.image ||
                                                        "https://i.imgur.com/fSmqcDf.png"
                                                    }
                                                    style={{
                                                        width: 60,
                                                        height: 60,
                                                    }}
                                                />
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>
                                                {line.quantity}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>
                                                {line?.item?.sku || "-"}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>
                                                {line?.item?.name || "-"}
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                borderRightWidth: 0,
                                            }}
                                        >
                                            <Text style={styles.tableCell}>
                                                {formatAsCurrency(
                                                    includeTax
                                                        ? line.linePriceTotal
                                                              .incTax
                                                        : line.linePriceTotal
                                                              .excTax,
                                                    row.currency
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                ))}
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    );

    const [documentInstance, updateInstance] = usePDF({
        document: PickListDocument,
    });

    const updatePicklistStatus = orders?.pickListsPDF?.updateStatusToPicking;

    const onGeneratePdf = (selectedRows, download) => {
        if (updatePicklistStatus) {
            console.log("selectedRows", selectedRows);
        }

        if (download) {
            btnRef.current.click();
        } else {
            window.open(btnRef.current.href, "_blank");
        }
    };

    useEffect(() => {
        updateInstance();
        // eslint-disable-next-line
    }, [selectedRowData]);

    return (
        <>
            <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faList} size="1x" className="mr-2" />
                <p>
                    Generate {selectedRows.length} Pick List
                    {selectedRows.length !== 1 && `s`}.
                </p>
            </div>

            <div>
                <button
                    className="btn btn-outline-secondary mr-2 btn-cancel-delete"
                    onClick={() => instance.toggleRowSelectedAll(false)}
                >
                    Cancel
                </button>

                <button
                    className="btn btn-outline-success  mr-2"
                    onClick={() => onGeneratePdf(selectedRows)}
                >
                    View
                </button>

                <button
                    className="btn btn-success"
                    onClick={() => onGeneratePdf(selectedRows, true)}
                >
                    Download
                </button>

                <a
                    ref={btnRef}
                    style={{
                        height: 0,
                        width: 0,
                        opacity: 0,
                        margin: 0,
                        padding: 0,
                        overflow: "hidden",
                        position: "absolute",
                    }}
                    href={documentInstance.url}
                    download={`${dayjs().format("YYYYMMDD")}_PICK_SHEET.pdf`}
                >
                    Download pick sheet
                </a>
            </div>
        </>
    );
};
