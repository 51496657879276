import React, { HTMLAttributes } from "react";
import ReactSelect, { components } from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

type SelectInputProps = {
  options: {
    value: string;
    label: string;
    selected: boolean;
    disabled: boolean;
  }[];
  name: string;
  label?: string;
  required?: boolean;
  value?: { value: string; label: string };
  large?: boolean;
  onChange: (item: { value: string; label: string }) => void;
} & HTMLAttributes<HTMLDivElement>;

export const SelectInput = (props: SelectInputProps) => {
  const { className, name, label, large, required, options, onChange, value } =
    props;
  return (
    <>
      {label?.length > 0 && (
        <label htmlFor={name} className={`mb-[10px] text-sm block font-bold`}>
          {label}{" "}
          {required ? <span className="text-brand-danger">*</span> : null}
        </label>
      )}

      <div data-testid="react-select-component">
        <ReactSelect
          onChange={onChange}
          value={value}
          options={options}
          className={`react-select ${className}`}
          styles={{
            control: (provided, { isFocused, menuIsOpen }) => ({
              ...provided,
              padding: "0 5px",
              height: large ? "50px" : "45px",
              fontWeight: 600,
              backgroundColor: "#fff",
              cursor: "pointer",
              borderColor: "#DDDDDD",
              borderWidth: "1px",
              borderBottom: menuIsOpen ? "none" : "1px solid #DDDDDD",
              borderRadius: "0px",
              color: "#3F3D4E",
              boxShadow: isFocused ? "none" : "none",
              fontSize: "14px",
              "&:hover": {
                borderColor: "#DDDDDD",
              },
              transition: "none",
            }),
            valueContainer: (provided) => ({
              ...provided,
              height: large ? "50px" : "45px",
              lineHeight: "30px",
              borderRadius: "0px",

              borderColor: "#DDDDDD",
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              position: "relative",
              top: "-2px",
            }),
            singleValue: (provided) => ({
              ...provided,
              padding: "0",
              color: "#3F3D4E",
            }),
            menu: (provided) => ({
              ...provided,
              width: "calc(100% - 2px)",
              margin: "0",
              left: "1px",
              right: "1px",
              padding: "0px",
              color: "#3F3D4E",
              backgroundColor: "#fff",
              border: "none",
              borderRadius: "0px",
              fontWeight: 400,
              boxShadow: "0 0 0 1px #DDDDDD, 0 4px 11px #DDDDDD",
              overflow: "hidden",
              cursor: "pointer",
            }),
          }}
          placeholder={
            <p className="!text-[14px] !font-normal">Select an option</p>
          }
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          data-testid="select-input"
        />
      </div>
    </>
  );
};

const Option = ({
  innerProps,
  innerRef,
  children,
  isDisabled,
  isFocused,
  isSelected,
  ...restProps
}) => (
  <div
    ref={innerRef}
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 15px",
      fontSize: "14px",
      fontWeight: 400,
      color: "#3F3D4E",
      opacity: isDisabled ? 0.7 : 1,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: isFocused ? "#F7F7F7" : "#FFFFFF",
      "&:active": {
        backgroundColor: "#F7F7F7",
      },
    }}
    {...innerProps}
  >
    <span>{children}</span>

    {isSelected && <FontAwesomeIcon icon={faCheck} />}
  </div>
);

const CaretDownIcon = () => {
  return (
    <p>
      <FontAwesomeIcon
        icon={faChevronDown as any}
        className="h-[14px] w-[14px] text-brand-secondary"
      />
    </p>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
