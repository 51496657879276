import React from "react";
import { forwardRef } from "react";
import { Field } from "formik";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    /** This is used to show a label above the input. */
    label?: string;
    /** This is used when there is an error to display the error styles for the input. */
    hasError?: boolean;
    /** This is used when there is an error to display a message to the user. */
    errorMessage?: string;
    /** This is used to disable the hover styles when using the input on certain background colours e.g. grey hover on grey background. */
    disableHoverStyles?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { label, errorMessage, required, ...restProps } = props;

    return (
        <>
            {label && props.type !== "hidden" && (
                <label
                    htmlFor={restProps?.name || restProps?.id}
                    className={`mb-[10px] text-sm block font-bold`}
                >
                    {label}{" "}
                    {
                        required ? (
                            <span className="text-brand-danger">*</span>
                        ) : null /* (
                        <span className="italic text-[12px] font-light text-brand-grey">
                            {' '}
                            &#8212; optional
                        </span>
                    ) */
                    }
                </label>
            )}

            <Field name={props.name}>
                {({ field, form }) => (
                    <div className="flex flex-row relative border border-brand-grey bg-brand-white hover:border-brand-primary mb-[15px]">
                        <input
                            className={`absolute top-0 bottom-0 left-0 right-0 z-1 opacity-0 cursor-pointer !mb-0`}
                            ref={ref}
                            onChange={(e) => {
                                console.log("e", e.currentTarget.files[0]);
                                form.setFieldValue(
                                    field.name,
                                    e.currentTarget.files[0]
                                );
                            }}
                            type="file"
                            {...restProps}
                        />

                        <div className="uppercase font-heading font-bold whitespace-nowrap bg-brand-light-grey border-r border-r-brand-grey text-brand-black text-[16px] leading-[24px] pl-[30px] pr-[34px] pt-[11px] pb-[10px] hover:bg-brand-grey">
                            Choose file
                        </div>

                        <div className="px-[15px] pt-[11px] pb-[10px] overflow-hidden whitespace-nowrap text-ellipsis text-[14px] leading-[21px] ">
                            {field.value?.name
                                ? field.value.name
                                : "No file chosen"}
                        </div>
                    </div>
                )}
            </Field>

            {errorMessage && (
                <p className={`text-xs mt-0 mb-[15px] text-brand-danger block`}>
                    {errorMessage}
                </p>
            )}
        </>
    );
});

export default Input;
