import React from "react";

import sortBy from "lodash/sortBy";
import isObjectLike from "lodash/isObjectLike";

import { Image } from "../Image";
import { Button } from "components/Button";
import { StockMessage } from "components/StockMessage";
import { LoggedInContent } from "./LoggedInContent";

/** If you want to override the content below the title/separator, please provide React children */
export const ProductCard = (props) => {
  const {
    children,
    product,
    link,
    loggedIn,
    productNameProps,
    separatorProps,
    incTax,
    imagePresets,
    imageProps,
    productComparison,
    onClick,
    ...restProps
  } = props;

  const productImages = product.resources?.filter((r) => r.type === "image");
  const firstImage = productImages
    ? sortBy(productImages, ["displayOrder"])[0]
    : null;

  //default values as a temporary measure until we have a proper product data
  const listOfAssets = [
    { name: "Make", value: product?.attributes?.brand },
    { name: "Model", value: product?.attributes?.model || "Golf" },
    { name: "Trim", value: product?.attributes?.trim || "MK7 GTF" },
  ];

  return (
    <div
      {...restProps}
      className={`grid grid-cols-1 md:grid-cols-3 md:gap-x-[15px] border p-[15px] border-brand-grey ${
        restProps?.className || ""
      }`}
      data-testid={`product-card`}
    >
       <div className={`col-span-1 sm:pr-[15px]`}>
        <a
          href={`/products/${product.slug}`}
          className="!no-underline"
        >
            <Image
              className="md:max-w-[250px]"
              {...(isObjectLike(product.attributes) && {
                alt: product.attributes.product_name,
              })}
              src={firstImage?.location || "/images/placeholder.jpg"}
              data-testid={`product-card__media`}
              presets={imagePresets}
              {...imageProps}
            />
        </a>
        <div
          {...separatorProps}
          className={`h-[1px] bg-brand-grey my-[10px]`}
          data-testid={`product-card__separator`}
        />
        <img 
          src="/images/Partners/Ferrari.svg"
          width="40"
          height="40"
          alt="Brand Logo"
          className="mb-2"
        />
      </div>
      <div className={`col-span-2 grid grid-rows-8`}>
        <div className={`row-span-4`}>
          <a
            href={`/products/${product.slug}`}
            className="!no-underline"
            {...(typeof link === "function" && { onClick: link })}
          >
            <p
              {...productNameProps}
              className={`font-bold !text-[16px] lg:text-[18px] !leading-[20px] ${productNameProps?.className}`}
              data-testid={`product-card__name`}
            >
              {product.attributes.product_name}
            </p>
          </a>
          <p
            className={`!text-[12px] !leading-[20px]`}
            data-testid={`product-card__stock-number`}
          >
            {`Stock Number: ${product.sku}`}
          </p>
        </div>
        <div className={`row-span-1`}>
          <ul className={`!mb-0`}>
            {listOfAssets.map((asset) => (
              <li className={`flex !text-[12px] !leading-[20px] mb-0`}>
                <div className={`font-bold pr-2`}> {asset.name}: </div>{" "}
                {asset.value}
              </li>
            ))}
          </ul>
          <div
            {...separatorProps}
            className={`w-full h-[1px] bg-brand-grey my-[10px]`}
            data-testid={`product-card__separator`}
          />
        </div>
        <div className={`row-span-1`}>
          {loggedIn && product.prices && (
            <LoggedInContent
              incTax={incTax || true}
              prices={product.prices}
              data-testid={`product-card__logged-in-content`}
            />
          )}
        </div>
        <div className="flex flex-row justify-between my-[10px]">
          <StockMessage inStock={product.inStock || true} />
        </div>
        <div className={`row-span-1`}>
        <a
          href={`/products/${product.slug}`}
          className="!no-underline"
        >
          <Button

            variantColor="secondary"
            className="w-full sm:w-full"
            data-testid="product-card__add-to-basket"
          >
            VIEW PRODUCT
          </Button>
        </a>
        </div>
      </div>
    </div>
  );
};
ProductCard.defaultProps = {
  incTax: true,
  loggedIn: true,
  link: () => {
    console.log("clicking product link");
  },
};
