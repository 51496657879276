import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";
import TestHarnesses from "packages/test-harnesses";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";

import blocks from "./blocks";
import { menu } from "./menu";

import compact from "lodash/compact";
import startCase from "lodash/startCase";

import {
    Input,
    Textarea,
    Select,
    Radio,
    Checkbox,
    CheckboxGroup,
    File,
} from "./Input";

import { Button } from "./components/Button";
import { OrdersList, OrdersView } from "./packages/peracto-catalogue/Orders";

import * as buttonVariants from "./theme/buttonVariants";
import { colors, fonts } from "./theme/tailwind";
import UserList from "packages/UserList";

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser({ UserList }),
        PeractoCommon(),
        PeractoCatalogue({ OrdersList, OrdersView }),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
        TestHarnesses(),
    ],
    editorConfig: {
        isTailwind: true,
        theme: EditorTheme,
        colorOptions: compact(
            Object.entries(colors).map(([label, value]) => {
                return {
                    label: startCase(label.replaceAll("brand-", "")),
                    value,
                };
            })
        ),
        fonts,
        locales: ["en_GB"],
        blocks,
        buttonVariants,
        inputTypes: {
            Input,
            Textarea,
            Select,
            Radio,
            Checkbox,
            CheckboxGroup,
            File,
            Button,
        },
        stylesheets: [
            "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap",
        ],
        globalStyles: GlobalStyles,
        headings: [
            {
                model: "h1",
                title: "Huge Heading 1",
                class: "hugeHeading1List",
                view: {
                    name: "h1",
                    classes: "heading1-huge",
                },
                converterPriority: "high",
            },
        ],
    },
    features: {
        shippingServices: {
            deliveryDays: true,
            conditions: true,
        },
        products: {
            exportProductData: true,
            productVariants: true,
        },
        user: {
            userGroups: true,
            hideCreditTerms: true,
        },
    },
    menu,
});

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
);

export default App;
