import React from "react";

import Component from "./Component";
import Form from "./Form/ProductsForm";

import { defaultSettings } from "./defaults/settings";

const createPlugin = (settings) => {
  const mergedSettings = { ...defaultSettings, ...settings };

  return {
    FormComponent: (props) => <Form {...props} />,
    Component: (props) => <Component {...props} {...mergedSettings} />,

    name: "peracto/content/products",
    version: "1.0.0",
    text: mergedSettings.translations.pluginName,
    description: mergedSettings.translations.pluginDescription,
    IconComponent: mergedSettings.IconComponent,

    createInitialState: () => ({
      numberOfProducts: 4,
      category: "",
      sku: "",
      multiple: true,
    }),
  };
};

export default createPlugin;
